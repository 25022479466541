import React from 'react'
import ReactDOM from 'react-dom/client'

import App from '@newspaces/zero-app/App'
import { bootstrapAppContext } from '@newspaces/zero-app-context/AppContext'

import createContext from './context'


const main = async () => {
    await bootstrapAppContext(
        createContext({
            apps: [
                {
                    key: 'app',
                    scopes: ['organisation', 'organisationUser'],
                    import: () => import('./Admin'),
                },
            ],
        })
    )

    const root = ReactDOM.createRoot(document.querySelector('#root'))
    root.render(<App />)
}

main()
