import { configure } from '@newspaces/zero-base/libs/mobx'

// THIS MUST BE LOADED FIRST BEFORE ANYTHING ELSE
// eslint-disable-next-line import/order
import './i18n'

import Organisation from 'bb-example-base/models/Organisation'
import OrganisationUser from 'bb-example-base/models/OrganisationUser'
import UserData from 'bb-example-base/models/UserData'

import bb from './zones/bb'

configure({
    enforceActions: 'never',
})

export default settings => ({
    ...settings,
    Organisation,
    OrganisationUser,
    UserData,
    zones: [bb],
})
