import i18n from '@newspaces/zero-app/i18n'

import 'bb-example-base/i18n'

import enLanguage from './languages/en'

i18n.languages = ['en']

i18n.registerLanguage('en', enLanguage)

// use english as default
i18n.setDefaultLocale('en')
i18n.trySetDefaultLocale()

export default i18n
