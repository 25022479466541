/* eslint-disable max-len  */
/* eslint-disable no-template-curly-in-string */
export default {
    manageOrganisations: {
        newOrganisation: 'Create new Workspace',
        yourOrganisations: 'Your Workspaces',
        emptySearch: 'No workspaces found',
        moveOrganisation: 'Move workspace',
        leaveOrganisation: 'Leave workspace',
        create: {
            label: 'Create a new Workspace',
            description: 'A workspace manages all your identites and locks',
            name: 'Name your workspace',
            action: 'Create Workspace',
        },
    },
}
